import React from 'react';
import './Home.css';
import './Content.css';
import lockscreen from '../assets/Proximity-UI/lockscreen.mov';
import article from '../assets/Proximity-UI/article.mov';

const Home = () => {
    return (
        <div className="container content">
            <div className="row justify-content-center text-content">
                <div className="col-md-12 right-column ">
                    <div>
                        <div className="text-content">
                            <h1>Patents</h1>
                        </div>
                        {/* Add your right column content here */}
                    </div>
                </div>
            </div>
            <div className="row align-items-start  text-content">


                <div className="col-md-9 right-column">
                    <div className="text-content">
                        <h2>Methods for multimodal interaction using pen-based gestures</h2>
                        <h3>Y. Deng, Y. Zhao, C. Yan, A. Lui, W. Li, and Y. Yang</h3>
                    </div>
                </div>
                <div className="col-md-3 left-column">
                    <div className="text-content">
                        <h3>Filed 2025-01-16</h3>
                    </div>
                </div>
            </div>
            <div className="row align-items-start">
                <div className="col-md-9 right-column">
                    <div className="text-content">
                        <h2>Multi-modal interaction for selecting semantic regions in agent-based image editing</h2>
                        <h3>Y. Zhao, A. Lui, S. Chugh, C. Yan, and Y. Deng</h3>
                    </div>
                </div>
                <div className="col-md-3 left-column">
                    <div className="text-content">
                        <h3>Filed 2025-01-21</h3>
                    </div>
                </div>
            </div>
            <div className="row align-items-start">


                <div className="col-md-9 right-column">
                    <div className="text-content">
                        <h2>Smart Color Palette Generation for assisting users in drawing</h2>
                        <h3>Y. Zhao, M. Keyvanara, A. Lui, S. Chugh, J. Ye, C. Wang, and C. Yan</h3>
                    </div>
                </div>
                <div className="col-md-3 left-column">
                    <div className="text-content">
                        <h3>Filed 2024-12-10</h3>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Home;
